import { useEffect, useRef, useState } from 'react';
import { AiOutlineSwap } from 'react-icons/ai';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Adicionar ícones de seta

import { Form } from '@unform/web';

import Filter from '~/components/Filter';
import Select from '~/components/Select';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { insertElementInArray } from '~/utils';

import DataInput from '../DataInput/index';
import * as S from './styles';

const UserPoints = () => {
  const [points, setPoints] = useState([]);
  const [orderedPoints, setOrderedPoints] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [filters, setFilters] = useState([]);
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [order, setOrder] = useState('DESC');
  const [visiblePagesStart, setVisiblePagesStart] = useState(0); // Controle do início das páginas visíveis
  const formRef = useRef(null);

  const buscarExtrato = (usuId, filtros, dtIni, dtFim) => {
    api
      .post('/buscarextrato', {
        usu_id: usuId,
        filtros,
        dt_ini: dtIni ? new Date(dtIni) : null,
        dt_fim: dtFim ? new Date(dtFim) : null,
        current_page: currentPage,
        order,
      })
      .then((response) => {
        const newPoints = response.data?.data?.data;
        setPoints(newPoints);
        const digits = [];
        for (let i = 0; i < response.data.data.pages; i += 1) {
          digits.push(i + 1);
        }
        setPages(digits);
      });
  };

  useEffect(() => {
    if (filters.length <= 0) {
      buscarExtrato(parseJwt().id, 'todos');
    } else {
      buscarExtrato(parseJwt().id, filters, startDate, endDate);
    }
  }, [currentPage, order]);

  const orderOptions = [
    { value: 'DESC', label: 'Mais recentes' },
    { value: 'ASC', label: 'Mais antigos' },
  ];

  const checkboxOptions = [
    { id: 'todos', value: '-1', label: 'Todos' },
    { id: 'bonus', value: '6', label: 'Bônus' },
    { id: 'aniversario', value: '7', label: 'Aniversário' },
    { id: 'resgate', value: '4', label: 'Resgate' },
    { id: 'vendas', value: '1', label: 'Vendas' },
    { id: 'estorno', value: '5', label: 'Estorno' },
  ];

  const handleSubmit = (data) => {
    const arrayOfFilters = data.checkbox;

    let finalFilter = [];

    arrayOfFilters.forEach((filter) => {
      if (filter === '-1') finalFilter = 'todos';
    });

    if (finalFilter !== 'todos') {
      arrayOfFilters.forEach((filter) => {
        finalFilter.push(filter);
      });
    }

    setFilters(finalFilter);

    buscarExtrato(parseJwt().id, finalFilter, startDate, endDate);
  };

  const getTableHeads = () => {
    let heads = [
      <td>Data</td>,
      <td>Origem</td>,
      <td>Descrição</td>,
      <td>ID</td>,
      <td>Pontos</td>,
      <td>Status</td>,
      <td>Observação</td>,
      <td>Faixa</td>,
    ];

    if (parseJwt().canal_id === 2) {
      heads = insertElementInArray(heads, <td>Número da linha</td>, 3);
    }
    if (parseJwt().canal_id === 5) {
      heads = insertElementInArray(heads, <td>IDPDV</td>, 4);
    }
    return heads;
  };

  const getTableRows = (points) => {
    return points.map((point) => (
      <>
        {point.descricao !== 'Expiração de pontos' && (
          <tr>
            <td>{point.dataFormatada}</td>
            <td>{point.origem}</td>
            {/* {parseJwt().canal_id === 2 && <td>{point.faixa}</td>} */}
            <td>{point.descricao}</td>
            {parseJwt().canal_id === 2 && (
              <td>{point.portabilidade || point.msisdn || 'N/A'}</td>
            )}
            <td>{point.t_id}</td>
            {parseJwt().canal_id === 5 && <td>{point.idpdv || 'N/A'}</td>}
            <td>{point.pontos}</td>
            <td className={point.status}>{point.status}</td>
            <td>{point.detalhe || 'N/A'}</td>
            {point.detalhe && (
              <S.Detail className="detail">{point.detalhe}</S.Detail>
            )}
            <td>{point.faixa}</td>
          </tr>
        )}
      </>
    ));
  };

  const handleNextPages = () => {
    setVisiblePagesStart(visiblePagesStart + 10);
  };

  const handlePreviousPages = () => {
    setVisiblePagesStart(visiblePagesStart - 10);
  };

  return (
    <>
      <S.Wrapper>
        <S.Container>
          <S.DataWrapper className="row">
            <DataInput title="Selecione o periodo" setValue={setStartDate} />
            <DataInput className="pl-20" title="Até" setValue={setEndDate} />
          </S.DataWrapper>
          <S.DataWrapper>
            <Filter
              checkboxOptions={checkboxOptions}
              handleSubmit={handleSubmit}
              title="Visualizar Apenas"
              button="Consultar"
            />
          </S.DataWrapper>
          <S.DataHeader>
            <p> </p>
            <Form ref={formRef} initialData={{ order: 1 }} onSubmit={() => {}}>
              <div>
                <AiOutlineSwap size={20} />
                <span>Ordenar por:</span>
              </div>
              <Select
                name="order"
                options={orderOptions}
                placeholder="Mais Recentes"
                onChange={(e) => setOrder(e.value)}
              />
            </Form>
          </S.DataHeader>
          <S.TableContainer>
            <table cellSpacing="0" cellPadding="0">
              <thead className="points-thead">{getTableHeads()}</thead>
              <tbody>{getTableRows(points)}</tbody>
            </table>
          </S.TableContainer>
          <S.PageButtonsWrapper>
            {visiblePagesStart > 0 && (
              <S.PageArrowButton onClick={handlePreviousPages}>
                <FaArrowLeft />
              </S.PageArrowButton>
            )}
            {pages
              .slice(visiblePagesStart, visiblePagesStart + 10)
              .map((page) => (
                <S.PageButton
                  key={page}
                  className={`page-button ${
                    currentPage === page ? 'active' : ''
                  }`}
                  onClick={() => setCurrentPage(page)}
                  type="button"
                >
                  {page}
                </S.PageButton>
              ))}
            {visiblePagesStart + 10 < pages.length && (
              <S.PageArrowButton onClick={handleNextPages}>
                <FaArrowRight />
              </S.PageArrowButton>
            )}
          </S.PageButtonsWrapper>
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default UserPoints;
