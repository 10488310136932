import Carousel from 'react-elastic-carousel';
import { useEffect, useState } from 'react';
import RangeCard from './RangeCard';
import * as S from './styles';
import { parseJwt } from '~/services/auth';

const Classification = () => {
  const [faixa, setFaixa] = useState('');

  const buscarExtrato = () => {
    const faixa = parseJwt().faixa;
    setFaixa(faixa);
  };

  const getActiveIndex = () => {
    if (faixa === 'SILVER' || faixa === 'SILVER_MAGALU') return 0;
    if (faixa === 'GOLD' || faixa === 'GOLD_MAGALU') return 1;
    if (faixa === 'DIAMOND' || faixa === 'DIAMOND_MAGALU') return 2;
    if (faixa === 'GIGAVIP' || faixa === 'GIGAVIP_MAGALU') return 3;
    return 0;
  };

  useEffect(() => {
    buscarExtrato();
  }, []);

  if (faixa === undefined) return null;

  if (!faixa) {
    return (
      <S.Container>
        <S.Header>
          <S.Title>Classificação</S.Title>
        </S.Header>
        <S.Paragraph>
          Classificação não encontrada. Em caso de dúvidas, entre em contato com
          a Central de Atendimento.
        </S.Paragraph>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.Header>
        <S.Title>Classificação</S.Title>
      </S.Header>
      <S.Paragraph>
        A classificação da categoria será de acordo com os seus atingimentos e
        performance durante o ciclo vigente, recebendo a pontuação pela sua
        categoria atual.
      </S.Paragraph>
      <S.Carousel>
        <Carousel initialActiveIndex={getActiveIndex()}>
          <RangeCard
            type={'silver'}
            typeOvershadowed={'silver_overshadowed'}
            controls={'1 a 2 controles'}
            churn={'<=25%'}
            isActive={faixa === 'SILVER' || faixa === 'SILVER_MAGALU'}
          />
          <RangeCard
            type={'gold'}
            typeOvershadowed={'gold_overshadowed'}
            controls={'3 a 5 controles'}
            churn={'<=25%'}
            isActive={faixa === 'GOLD' || faixa === 'GOLD_MAGALU'}
          />
          <RangeCard
            type={'diamond'}
            typeOvershadowed={'diamond_overshadowed'}
            controls={'6 a 10 controles'}
            churn={'<=25%'}
            isActive={faixa === 'DIAMOND' || faixa === 'DIAMOND_MAGALU'}
          />
          <RangeCard
            type={'claro_vip'}
            typeOvershadowed={'claro_vip_overshadowed'}
            controls={'a partir de 11'}
            churn={'<=10%'}
            isActive={faixa === 'GIGAVIP' || faixa === 'GIGAVIP_MAGALU'}
          />
        </Carousel>
      </S.Carousel>
      <S.Ranges>
        <RangeCard
          type={'silver'}
          typeOvershadowed={'silver_overshadowed'}
          controls={'1 a 2 controles'}
          churn={'<=25%'}
          isActive={faixa === 'SILVER' || faixa === 'SILVER_MAGALU'}
        />
        <RangeCard
          type={'gold'}
          typeOvershadowed={'gold_overshadowed'}
          controls={'3 a 5 controles'}
          churn={'<=25%'}
          isActive={faixa === 'GOLD' || faixa === 'GOLD_MAGALU'}
        />
        <RangeCard
          type={'diamond'}
          typeOvershadowed={'diamond_overshadowed'}
          controls={'6 a 10 controles'}
          churn={'<=25%'}
          isActive={faixa === 'DIAMOND' || faixa === 'DIAMOND_MAGALU'}
        />
        <RangeCard
          type={'claro_vip'}
          typeOvershadowed={'claro_vip_overshadowed'}
          controls={'a partir de 11'}
          churn={'<=10%'}
          isActive={faixa === 'GIGAVIP' || faixa === 'GIGAVIP_MAGALU'}
        />
      </S.Ranges>
    </S.Container>
  );
};

export default Classification;
