import { useState, useEffect } from 'react';
import StatusTag from '~/components/NexAdmin/StatusTag';
import { HeaderWrapper } from '~/styles/Layouts/NexAdmin/wrappers';
import DashDescription from '~/components/NexAdmin/Modal/Descriptions/dashDescription';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import { parseJwt } from '../../../services/auth';
import api from '~/services/api';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import * as S from './styles';

const RockDash = () => {
  const [loading, setLoading] = useState(true);
  const [powerBiEmbedConfig, setPowerBiEmbedConfig] = useState(null);
  const user = parseJwt();

  useEffect(() => {
    let isMounted = true;

    const fetchDashboardData = async () => {
      try {
        const channel = user?.canal_id;
        const response = await api.get(`/dashboard?channel=${channel}`);

        if (isMounted && response.data) {
          const embedUrl = response.data.embedReport[0].embedUrl;
          const reportId = response.data.embedReport[0].reportId;
          const embedToken = response.data.embedToken.token;

          setPowerBiEmbedConfig({
            type: 'report',
            id: reportId,
            embedUrl: embedUrl,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed,
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
            },
          });
        }
      } catch (error) {
        console.error('Erro ao buscar dados do dashboard: ', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchDashboardData();

    return () => {
      isMounted = false;
    };
  }, [user.canal_id]);

  const { openModal } = useModal();

  const openThermsModal = () => {
    openModal({
      title: 'Rock Dash',
      close: true,
      content: <DashDescription />,
    });
  };

  if (loading) return <div>Carregando...</div>;

  return (
    <>
      <HeaderWrapper>
        <StatusTag
          icon="projects"
          title="Rock Dash"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <S.TableContainer>
        {powerBiEmbedConfig ? (
          <PowerBIEmbed
            embedConfig={powerBiEmbedConfig}
            style={{ paddingTop: '100px' }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  function () {
                    console.log('Dashboard carregado');
                  },
                ],
                [
                  'rendered',
                  function () {
                    console.log('Dashboard renderizado');
                  },
                ],
                [
                  'error',
                  function (event) {
                    console.log('Erro no Power BI:', event.detail);
                  },
                ],
              ])
            }
            cssClassName={'powerbi-container'}
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        ) : (
          <div>Dashboard não disponível</div>
        )}
      </S.TableContainer>
    </>
  );
};

export default RockDash;
