const na = 'N/A';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
};

const columns = {
  2: [
    {
      name: 'CPF',
      selector: (row) => row['cpf'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['cpf'] ?? na}</div>,
    },
    {
      name: 'Nome',
      selector: (row) => row['nome'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['nome'] ?? na}</div>,
    },
    {
      name: 'Faixa',
      selector: (row) => row['label'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['label'] ?? na}</div>,
    },
    {
      name: 'Data de Início',
      selector: (row) => formatDate(row['inicioFaixa']) ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {formatDate(row['inicioFaixa']) ?? na}
        </div>
      ),
    },
    {
      name: 'Data de Fim',
      selector: (row) => formatDate(row['fimFaixa']) ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{formatDate(row['fimFaixa']) ?? na}</div>
      ),
    },
  ],
};

export default columns;
