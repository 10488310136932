import { useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import { InputUpload, ToggleSwitch } from '~/components/NexAdmin/Inputs';
import * as S from './styles';
import { Form } from '@unform/web';
import Button from '~/components/NexAdmin/Buttons/Button';
import getIcon from '~/Helpers/getIcon';
import DownloadStaticFile from '~/components/NexAdmin/DownloadStaticFile';
import columns from './columns';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import Table from '~/components/NexAdmin/Table';
import moment from 'moment';

const model = [
  {
    CPF: 11111111111,
    FAIXA: 'SILVER',
    DT_INICIO: '2024-10-01 00:00:00.000',
    DT_FIM: '2024-10-30 00:00:00.000',
  },
  {
    CPF: 12720988774,
    FAIXA: 'SILVER',
    DT_INICIO: '2024-10-01 00:00:00.000',
    DT_FIM: '2024-10-30 00:00:00.000',
  },
];

const file_columns = [
  {
    name: 'CPF',
    selector: 'CPF',
  },
  {
    name: 'FAIXA',
    selector: 'FAIXA',
  },
  {
    name: 'DT_INICIO',
    selector: 'DT_INICIO',
  },
  {
    name: 'DT_FIM',
    selector: 'DT_FIM',
  },
];

const WalletUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState([]);

  const validate = () => {
    setErrors([]);
    setData(null);

    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );

    setLoading(true);

    const formData = new FormData();

    formData.append('sheet', file);

    api
      .post('/usuario/faixa/processar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        const { data, errors } = response.data;
        if (data && data.length) {
          setData(data);
          showNotification(
            'success',
            'Sucesso',
            'Faixas salvas com sucesso! Elas serão processadas em breve.',
          );
          setFile(null);
        }
        if (errors && errors.length) {
          setErrors(errors);
        }
      })
      .catch((error) => {
        HTTPErrorCallback(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columnsWithErrors = [
    ...columns,
    {
      name: 'Erro',
      selector: (row) => row.ERROR,
      cell: (row) => (
        <div title={row.ERROR}>
          {row.ERROR && Object.keys(row.ERROR).length
            ? JSON.stringify(row.ERROR)
            : 'Erro não especificado'}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="actions" title="Atualização de Faixas" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.Wrapper>
              <h2>Selecione um arquivo para validação</h2>

              <S.InputWrapper>
                <Form>
                  <InputUpload
                    name="file"
                    onGetFile={(e) => {
                      setData(null);
                      setErrors([]);

                      setFile(e);
                    }}
                    placeholder={
                      <>{getIcon('upload')} Selecione um arquivo para upload</>
                    }
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </Form>
                <Button type="button" width="50%" onClick={validate}>
                  Processar
                </Button>
              </S.InputWrapper>

              {errors.length > 0 && (
                <S.TableWrapper>
                  <h2>Os dados abaixo contêm erros e não serão processados</h2>
                  <Table
                    columns={columnsWithErrors}
                    data={errors}
                    dataTableProps={{ pagination: true }}
                    header={true}
                    fileName={`erros_validação_faixas_${moment().format(
                      'DD-MM-YYYY',
                    )}`}
                    hasDownload={true}
                  />
                </S.TableWrapper>
              )}
            </S.Wrapper>

            <DownloadStaticFile
              columns={file_columns}
              list={model}
              file_name={'validacao_faixas_modelo'}
              button_name={'Download Modelo'}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default WalletUpdate;
