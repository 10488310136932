import React from 'react';
import * as S from './styles';
import Icon from '../Icons';

const RangeCard = (props) => {
  const { isActive } = props;

  return (
    <S.RangeCard isActive={isActive}>
      <Icon type={isActive ? props.type : props.typeOvershadowed} />
      {isActive && <S.CurrentLevel>Nível Atual</S.CurrentLevel>}
      <S.DescriptionContainer>
        <S.Topic>
          Produtividade:
          <S.Description>{props.controls}</S.Description>
        </S.Topic>
        <S.Topic>
          Early churn:
          <S.Description>{props.churn}</S.Description>
        </S.Topic>
      </S.DescriptionContainer>
    </S.RangeCard>
  );
};

export default RangeCard;
