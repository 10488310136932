import React from 'react';
import Silver from './Silver positivo.svg';
import Gold from './Gold positivo.svg';
import Diamond from './Diamond positivo.svg';
import Vip from './Vip positivo.svg';
import Silver_overshadowed from './Silver.svg';
import Gold_overshadowed from './Gold.svg';
import Diamond_overshadowed from './Diamond.svg';
import Vip_overshadowed from './Vip.svg';

const icons = {
  silver: Silver,
  gold: Gold,
  diamond: Diamond,
  claro_vip: Vip,
  silver_overshadowed: Silver_overshadowed,
  gold_overshadowed: Gold_overshadowed,
  diamond_overshadowed: Diamond_overshadowed,
  claro_vip_overshadowed: Vip_overshadowed,
};

const Icon = ({ type }) => {
  const SelectedIcon = icons[type];
  return SelectedIcon ? <img src={SelectedIcon} alt={type} /> : null;
};

export default Icon;
