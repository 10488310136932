const na = 'N/A';
const columns = {
  5: [
    {
      name: 'ID Venda',
      selector: (row) => row['ID Venda'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['ID Venda'] ?? na}</div>
      ),
    },
    {
      name: 'Data Geração Ponto',
      selector: (row) => row['Data'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Data'] ?? na}</div>,
    },
    {
      name: 'Nome',
      selector: (row) => row['Nome'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Nome'] ?? na}</div>,
    },
    {
      name: 'CPF/CNPJ',
      selector: (row) => row['CPF/CNPJ'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['CPF/CNPJ'] ?? na}</div>
      ),
    },
    {
      name: 'Razão Social',
      selector: (row) => row['Razao Social'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Razao Social'] ?? na}</div>
      ),
    },
    {
      name: 'Nome Fantasia',
      selector: (row) => row['Nome Fantasia'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Nome Fantasia'] ?? na}</div>
      ),
    },
    {
      name: 'Cargo',
      selector: (row) => row['Cargo'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Cargo'] ?? na}</div>,
    },
    {
      name: 'Pct do Cargo',
      selector: (row) => row['Pct Cargo'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Pct Cargo'] ?? na}</div>
      ),
    },
    {
      name: 'PDV',
      selector: (row) => row['PDV'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['PDV'] ?? na}</div>,
    },
    {
      name: 'Regional',
      selector: (row) => row['Regional'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Regional'] ?? na}</div>
      ),
    },
    {
      name: 'Rede',
      selector: (row) => row['rede'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['rede'] ?? na}</div>,
    },
    {
      name: 'Produto',
      selector: (row) => row['Produto'] ?? na,
      sortable: true,
      minWidth: '200px',
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Produto'] ?? na}</div>
      ),
    },
    {
      name: 'Classificação',
      selector: (row) => row['Classificação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row['Classificação'] && row['Classificação'] !== ''
            ? row['Classificação']
            : na}
        </div>
      ),
    },
    {
      name: 'Valor do Produto',
      selector: (row) => row['Valor'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Valor'] ?? na}</div>,
    },
    {
      name: 'Tipo Serviço',
      selector: (row) => row['Tipo Serviço'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Tipo Serviço'] ?? na}</div>
      ),
    },
    {
      name: 'Ponto',
      selector: (row) => row['Ponto'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Ponto'] ?? na}</div>,
    },
    {
      name: 'Ponto Bônus',
      selector: (row) => row['Ponto Bônus'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Ponto Bônus'] ?? na}</div>
      ),
    },
    {
      name: 'Tipo Transação',
      selector: (row) => row['Tipo Transação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Tipo Transação'] ?? na}</div>
      ),
    },
    {
      name: 'Motivo Reprovação',
      selector: (row) => row['Motivo Reprovação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Motivo Reprovação'] ?? na}</div>
      ),
    },
    {
      name: 'Perfil Detalhe',
      selector: (row) => row['Perfil Detalhe'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Perfil Detalhe'] ?? na}</div>
      ),
    },
  ],
  2: [
    {
      name: 'ID Venda',
      selector: (row) => row['ID Venda'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['ID Venda'] ?? na}</div>
      ),
    },
    {
      name: 'Data',
      selector: (row) => row['Data'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Data'] ?? na}</div>,
    },
    {
      name: 'Nome',
      selector: (row) => row['Nome'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Nome'] ?? na}</div>,
    },
    {
      name: 'CPF',
      selector: (row) => row['CPF'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['CPF'] ?? na}</div>,
    },
    {
      name: 'Cargo',
      selector: (row) => row['Cargo'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Cargo'] ?? na}</div>,
    },
    {
      name: 'Pct do Cargo',
      selector: (row) => row['Pct Cargo'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Pct Cargo'] ?? na}</div>
      ),
    },
    {
      name: 'PDV',
      selector: (row) => row['PDV'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['PDV'] ?? na}</div>,
    },
    {
      name: 'Regional',
      selector: (row) => row['Regional'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Regional'] ?? na}</div>
      ),
    },
    {
      name: 'Rede',
      selector: (row) => row['rede'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['rede'] ?? na}</div>,
    },
    {
      name: 'Produto',
      selector: (row) => row['Produto'] ?? na,
      sortable: true,
      minWidth: '200px',
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Produto'] ?? na}</div>
      ),
    },
    {
      name: 'Classificação',
      selector: (row) => row['Classificação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row['Classificação'] && row['Classificação'] !== ''
            ? row['Classificação']
            : na}
        </div>
      ),
    },
    {
      name: 'Valor do Produto',
      selector: (row) => row['Valor'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Valor'] ?? na}</div>,
    },
    {
      name: 'Tipo Transação',
      selector: (row) => row['Tipo Transação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Tipo Transação'] ?? na}</div>
      ),
    },
    {
      name: 'Detalhe Transação',
      selector: (row) => row['Detalhe Transação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Detalhe Transação'] ?? na}</div>
      ),
    },
    {
      name: 'Pontos',
      selector: (row) => row['Pontos'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Pontos'] ?? na}</div>,
    },
    {
      name: 'Bônus',
      selector: (row) => row['Bônus'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Bônus'] ?? na}</div>,
    },
    {
      name: 'Faixa',
      selector: (row) => row['faixa'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['faixa'] ?? na}</div>,
    },
  ],
  6: [
    {
      name: 'ID Venda',
      selector: (row) => row['ID Venda'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['ID Venda'] ?? na}</div>
      ),
    },
    {
      name: 'Data',
      selector: (row) => row['Data'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Data'] ?? na}</div>,
    },
    {
      name: 'Nome',
      selector: (row) => row['Nome'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Nome'] ?? na}</div>,
    },
    {
      name: 'CPF/CNPJ',
      selector: (row) => row['CPF/CNPJ'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['CPF/CNPJ'] ?? na}</div>
      ),
    },
    {
      name: 'Cargo',
      selector: (row) => row['Cargo'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Cargo'] ?? na}</div>,
    },
    {
      name: 'Percentual Cargo',
      selector: (row) =>
        row['Percentual Cargo']
          ? `${row['Percentual Cargo'].toString().split('.')[0]}%`
          : na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row['Percentual Cargo']
            ? `${row['Percentual Cargo'].toString().split('.')[0]}%`
            : na}
        </div>
      ),
    },
    {
      name: 'SAP',
      selector: (row) => row['SAP'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['SAP'] ?? na}</div>,
    },
    {
      name: 'Tier',
      selector: (row) => row['Tier'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Tier'] ?? na}</div>,
    },
    {
      name: 'Regional',
      selector: (row) => row['Regional'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Regional'] ?? na}</div>
      ),
    },
    {
      name: 'Rede',
      selector: (row) => row['rede'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['rede'] ?? na}</div>,
    },
    {
      name: 'Loja',
      selector: (row) => row['Loja'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Loja'] ?? na}</div>,
    },
    {
      name: 'Produto',
      selector: (row) => row['Produto'] ?? na,
      sortable: true,
      minWidth: '200px',
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Produto'] ?? na}</div>
      ),
    },
    {
      name: 'Tipo',
      selector: (row) => row['Tipo'] ?? na,
      sortable: true,
      minWidth: '200px',
      cell: (row) => <div data-tag="allowRowEvents">{row['Tipo'] ?? na}</div>,
    },
    {
      name: 'Ponto',
      selector: (row) => row['Ponto'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Ponto'] ?? na}</div>,
    },
    {
      name: 'Ponto Bônus',
      selector: (row) => row['Ponto Bônus'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Ponto Bônus'] ?? na}</div>
      ),
    },
    {
      name: 'Tipo Transação',
      selector: (row) => row['Tipo Transação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Tipo Transação'] ?? na}</div>
      ),
    },
    {
      name: 'Detalhe Transação',
      selector: (row) => row['Detalhe Transação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Detalhe Transação'] ?? na}</div>
      ),
    },
  ],
  8: [
    {
      name: 'ID Venda',
      selector: (row) => row['ID Venda'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['ID Venda'] ?? na}</div>
      ),
    },
    {
      name: 'Data',
      selector: (row) => row['Data'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Data'] ?? na}</div>,
    },
    {
      name: 'Nome',
      selector: (row) => row['Nome'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Nome'] ?? na}</div>,
    },
    {
      name: 'CPF/CNPJ',
      selector: (row) => row['CPF/CNPJ'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['CPF/CNPJ'] ?? na}</div>
      ),
    },
    {
      name: 'Cargo',
      selector: (row) => row['Cargo'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Cargo'] ?? na}</div>,
    },
    {
      name: 'Regional',
      selector: (row) => row['Regional'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Regional'] ?? na}</div>
      ),
    },
    {
      name: 'Rede',
      selector: (row) => row['rede'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['rede'] ?? na}</div>,
    },
    {
      name: 'Produto',
      selector: (row) => row['Produto'] ?? na,
      sortable: true,
      minWidth: '200px',
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Produto'] ?? na}</div>
      ),
    },
    {
      name: 'Classifição',
      selector: (row) => row['Classifição'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Classifição'] ?? na}</div>
      ),
    },
    {
      name: 'G20',
      selector: (row) => row['G20'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['G20'] ?? na}</div>,
    },
    {
      name: 'BKO',
      selector: (row) => row['BKO'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['BKO'] ?? na}</div>,
    },
    {
      name: 'Segmento',
      selector: (row) => row['Segmento'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Segmento'] ?? na}</div>
      ),
    },
    {
      name: 'Ponto',
      selector: (row) => row['Ponto'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Ponto'] ?? na}</div>,
    },
    {
      name: 'Ponto Bônus',
      selector: (row) => row['Ponto Bônus'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Ponto Bônus'] ?? na}</div>
      ),
    },
    {
      name: 'Tipo Transação',
      selector: (row) => row['Tipo Transação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Tipo Transação'] ?? na}</div>
      ),
    },
    {
      name: 'Detalhe Transação',
      selector: (row) => row['Detalhe Transação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Detalhe Transação'] ?? na}</div>
      ),
    },
  ],
  9: [
    {
      name: 'Data',
      selector: (row) => row['Data'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Data'] ?? na}</div>,
    },
    {
      name: 'Nome',
      selector: (row) => row['Nome'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Nome'] ?? na}</div>,
    },
    {
      name: 'CPF/CNPJ',
      selector: (row) => row['CPF/CNPJ'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['CPF/CNPJ'] ?? na}</div>
      ),
    },
    {
      name: 'Segmento',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.segmento ? row.segmento : na),
    },
    {
      name: 'Cargo',
      selector: (row) => row['Cargo'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Cargo'] ?? na}</div>,
    },
    {
      name: 'Regional',
      selector: (row) => row['Regional'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Regional'] ?? na}</div>
      ),
    },
    {
      name: 'Rede',
      selector: (row) => row['rede'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['rede'] ?? na}</div>,
    },
    {
      name: 'Produto',
      selector: (row) => row['Produto'] ?? na,
      sortable: true,
      minWidth: '200px',
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Produto'] ?? na}</div>
      ),
    },
    {
      name: 'Ponto',
      selector: (row) => row['Ponto'] ?? na,
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row['Ponto'] ?? na}</div>,
    },
    {
      name: 'Ponto Bônus',
      selector: (row) => row['Ponto Bônus'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Ponto Bônus'] ?? na}</div>
      ),
    },
    {
      name: 'Tipo Transação',
      selector: (row) => row['Tipo Transação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Tipo Transação'] ?? na}</div>
      ),
    },
    {
      name: 'Detalhe Transação',
      selector: (row) => row['Detalhe Transação'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Detalhe Transação'] ?? na}</div>
      ),
    },
  ],
};

export default columns;
