import styled from 'styled-components';
import media from 'styled-media-query';

export const RangeCard = styled.div`
  width: 23%;
  display: flex;
  flex-direction: column;
  /* padding: 10px 0px; */
  border: 2px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.footer : theme.colors.grayLight};
  border-radius: 10px;
  height: max-content;
  min-height: 220px;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ isActive, theme }) =>
    isActive ? 'white' : theme.colors.grayLight};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};

  ${media.lessThan('768px')`
    width: 100%;
  `}
  margin-top: 10px;
`;

export const CurrentLevel = styled.div`
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};
  padding: 5px 10px;
  border-radius: 20px;
  text-align: center;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Topic = styled.p`
  display: flex;
  flex-direction: row;
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ isActive, theme }) => (isActive ? '' : theme.colors.grayDarker)};
  ${media.lessThan('1550px')`
    flex-direction: column; 
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    text-align: center;
    
  `}
  ${media.lessThan('1000px')`
    flex-direction: column; 
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    text-align: center;
    
  `}
`;

export const Description = styled.p`
  font-weight: ${({ theme }) => theme.font.light};
  margin-left: 5px;
`;
