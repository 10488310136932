const columns = [
  {
    name: 'CPF',
    selector: (row) => row.CPF,
    cell: (row) => <div title={row.CPF}>{row.CPF}</div>,
    sortable: true,
  },
  {
    name: 'Faixa',
    selector: (row) => row.FAIXA,
    cell: (row) => <div title={row.FAIXA}>{row.FAIXA}</div>,
    sortable: true,
  },
  {
    name: 'Data de Inicio',
    selector: (row) => row.DT_INICIO,
    cell: (row) => <div title={row.DT_INICIO}>{row.DT_INICIO || 'N/A'}</div>,
    sortable: true,
  },
  {
    name: 'Data de fim',
    selector: (row) => row.DT_FIM,
    cell: (row) => <div title={row.DT_FIM}>{row.DT_FIM || 'N/A'}</div>,
    sortable: true,
  },
];

export default columns;
