import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  grid-area: classification;
  background-color: white;
  background-color: #fff;
  border-radius: 10px;
  padding: 17px 15px;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Title = styled.p`
  margin-left: 15px;
  font-size: ${({ theme }) => theme.font.sizes.medium};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
  margin-bottom: 20px;
`;

export const Paragraph = styled.p`
  margin-left: 15px;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.grayMedium};
  font-weight: 400;
`;

export const Ranges = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  ${media.lessThan('768px')`
    display: none;
  `}
`;

export const RangeCard = styled.div`
  width: 23%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px;
  background-color: ${({ active }) => (active ? 'white' : '#F3F3F3')};
  border: ${({ active }) => (active ? '2px solid red' : 'none')};
  border-radius: 10px;
  height: max-content;
  min-height: 200px;
`;

export const Carousel = styled.div`
  display: none;

  ${media.lessThan('768px')`
    display: block;
  `}
  .rec-dot {
    background-color: ${({ theme }) => theme.colors.grayLighter};
    border: none !important;
    box-shadow: none;
  }

  .rec-dot_active {
    background-color: ${({ theme }) => theme.colors.red};
    border: none !important;
  }

  .rec-arrow {
    display: none;
  }
`;
