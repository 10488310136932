export const faq_bko_pap = [
  {
    id: 1,
    pergunta: 'O que é Campanha Giga Clube?',
    resposta:
      'É um Programa de Incentivo que ocorrerá em todo o território nacional. Nele os participantes poderão trocar pontos por produtos e experiencias com base na pontuação gerada a partir das tratativas de contratos.',
  },
  {
    id: 2,
    pergunta: 'Qual o período da campanha?',
    resposta: 'A campanha acontece entre os dias 01/08/2024 à 31/01/2025',
  },
  {
    id: 3,
    pergunta: 'Qual o objetivo da campanha?',
    resposta:
      'Incentivar os participantes que apresentarem o melhor desempenho e qualidade em vendas durante o período da campanha, atribuindo-lhes pontos de acordo com os critérios estabelecidos. Esses pontos podem ser convertidos em prêmios via resgate no catálogo de prêmios.',
  },
  {
    id: 4,
    pergunta: 'Quem pode participar da campanha?',
    resposta:
      'Podem participar todos os membros da equipe de vendas e perfis gerenciais, conforme abaixo:Vendedores – Canal PAP Indireto, Supervisores de Vendas – Canal PAP Indireto, BKO – Canal PAP Indireto, Supervisores de BKO – Canal PAP Indireto',
  },
  {
    id: 5,
    pergunta: 'Como faço para me cadastrar no Giga Clube?',
    resposta:
      'Para participar do Giga Clube é necessário estar ativo na base de usuários, estar elegível as regras do programa e acessar o site do Giga Clube site do www.gigaclube.com e realizar o cadastro com todas as informações solicitadas, incluindo o CPF e o login Claro e aceitar o Regulamento. Essas informações serão validadas na Claro.',
  },
  {
    id: 6,
    pergunta: 'Como acesso a conta Giga Clube?',
    resposta:
      'Para acessar a conta do Giga Clube, basta acessar o site www.gigaclube.com ou pelo Aplicativo (somente disponível para celulares com sistema operacional Android), com login e senha de acesso criado.',
  },
  {
    id: 7,
    pergunta:
      'Esqueci minha senha ou login de acesso ao site Giga Clube. O que faço?',
    resposta:
      'Efetuar o processo de “esqueci minha senha” na home do site www.gigaclube.com e seguir o passo a passo.',
  },
  {
    id: 8,
    pergunta: 'Como faço para alterar meus dados cadastrais?',
    resposta:
      'Para alteração de dados cadastrais, é necessário entrar em contato com o SAC da campanha e pedir a liberação através do WhatsApp 11 2776-6205. Será necessário solicitar uma liberação ao atendimento, após liberação do SAC, o participante deverá realizar a alteração em sistema.',
  },
  {
    id: 9,
    pergunta: 'Quais produtos e serviços fazem parte da campanha?',
    resposta:
      'Para o Canal PaP Indireto, são consideradas as tratativas de vendas de Produtos Residenciais (Banda Larga, TV, Fixo) instalados.',
  },
  {
    id: 10,
    pergunta: 'Quais produtos e serviços fazem parte da campanha?',
    resposta:
      'Para o Canal PaP Indireto, são consideradas as vendas de Produtos Residenciais (Banda Larga, TV e Combo Multi) instalados.',
  },

  {
    id: 11,
    pergunta: 'Quais produtos e serviços não fazem parte da campanha?',
    resposta:
      'Ponto adicional, Up-grade de plano, marcação M-Play não são elegíveis a esse programa.',
  },
  {
    id: 12,
    pergunta: 'A partir de qual data eu posso pontuar?',
    resposta:
      'Suas vendas serão contabilizadas e renderão pontos a partir do dia 01/08/2024 sendo, que para o recebimento das vendas retroativas do mês de agosto, o participante deverá se cadastrar e aceitar o regulamento dentro do período de 09/09/2024 a 20/09/2024.',
  },
  {
    id: 13,
    pergunta: 'Qual a forma de apuração dos resultados?',
    resposta:
      'A base utilizada para apuração das vendas, será o “Painel Residencial PAP”, aba “Telinha”. Extraída e enviada diariamente pelo time de Planejamento e Monitoramento. As informações dos participantes serão consideradas no momento da extração dos dados, não sendo possível contestar Status, cargos ou outros critérios de elegibilidade.',
  },
  {
    id: 14,
    pergunta: 'Qual a conversão de venda por ponto?',
    resposta:
      'Para entender quanto cada serviço e produto vendido vale em pontos, acesse regulamento no site da campanha e verifique o anexo I.',
  },
  {
    id: 15,
    pergunta: 'A partir de quando posso resgatar meus pontos?',
    resposta:
      'Você pode usar seus pontos assim que eles forem validados ou então acumulá-los até a data limite de 15/02/2025. Fique atento, pois todos os pontos devem ser resgatados durante o período da campanha.',
  },
  {
    id: 16,
    pergunta: 'Onde eu resgato meus pontos?',
    resposta:
      'Você pode resgatar seus pontos através do Catálogo de Prêmios disponível no menu ao lado esquerdo do site.',
  },
  {
    id: 17,
    pergunta:
      'Quais os serviços/produtos posso utilizar com os pontos adquiridos?',
    resposta:
      'Os pontos da campanha podem ser resgatados via catálogo de prêmios, onde consta uma grande variedade de produtos e serviços de diferentes parceiros, tais como: Magazine Luiza, Uber, Shell, Fastshop, Casas Bahia, Extra, Tramontina, Netshoes, Americanas, Petz, Recarga de Celular, Pagamento de Contas, entre outros.',
  },
  {
    id: 18,
    pergunta: 'Em caso de dúvidas entre em contato:',
    resposta:
      'Você pode entrar em contato com nossa equipe via WhatsApp, pelo número (11) 2776-6205 (SLA primeiro retorno 48hrs úteis). O atendimento será dentro do horário comercial (09:00 hrs às 18:00hrs) de segunda a sexta-feira, exceto em feriados nacionais. E para dúvidas em relação ao seu produto resgatado ou catálogo de prêmios, entre em contato com a Vertem através do chat na página do catálogo, atendimento telefônico pelo número (11)3504-4730 (SLA primeiro retorno 48hrs úteis) O atendimento será dentro do horário comercial (09:00 hrs às 18:00hrs) de segunda a sextafeira, exceto em feriados nacionais. É dever do Participante responder todas as perguntas e dados corretamente, para dar sequência a tratativa.',
  },
  {
    id: 19,
    pergunta: 'Onde posso acessar o regulamento?',
    resposta: 'Ele estará disponível no site da campanha www.gigaclube.com.',
  },
];

export const faq_bko = [
  {
    id: 1,
    pergunta: 'O que é Campanha Giga Clube?',
    resposta:
      'É um Programa de Incentivo com vigência em todo o território nacional, regido pelas condições estabelecidas e destinadas ao Canal de Varejo.',
  },
  {
    id: 2,
    pergunta: 'Qual o período da campanha?',
    resposta: 'A campanha acontece entre os dias 01/08/2024 à 31/01/2025',
  },
  {
    id: 3,
    pergunta: 'Qual o objetivo da campanha?',
    resposta:
      'Incentivar os participantes que apresentarem o melhor desempenho e qualidade na tratativa dos contratos durante o período da campanha, atribuindo-lhes pontos de acordo com os critérios estabelecidos. Esses pontos são convertidos em prêmios via resgate no catálogo de prêmios.',
  },
  {
    id: 4,
    pergunta: 'Quem pode participar da campanha?',
    resposta:
      'Profissionais com Gestão Residencial, assim considerados BackOffice. Os perfis de Supervisor BKO Residencial também pontuarão, de acordo com as regras estabelecidas no Regulamento.',
  },
  {
    id: 5,
    pergunta: 'Quais produtos e serviços fazem parte da campanha?',
    resposta:
      'Para o Assistente de BKO serão elegíveis os contratos tratados, Venda Bruta e Vendas Instaladas. Para o Supervisor de BKO serão elegíveis os contratos tratados pela equipe, de acordo com as regras: Elegibilidade VB proposta regional 80% Elegibilidade Produtividade BKO 80 Pontos Bônus HC 500 Pontuação Máxima 4.000 • O Supervisor de Backoffice ganhará 500 pontos de bônus, por cada assistente de BKO que cadastrar o mínimo de 100 contratos (VB) - a pontuação máxima é de 4.000 pontos por Supervisor; • A elegibilidade para o ganho dos pontos depende do atingimento mínimo de 80%, de conversão de venda bruta sobre proposta da regional que o assistente atende – é necessário o atingimento mínimo de conversão para que o Supervisor receba a pontuação referente ao assistente.',
  },
  {
    id: 6,
    pergunta: 'A partir de qual data eu posso pontuar?',
    resposta:
      'Suas vendas serão contabilizadas e renderão pontos a partir do dia 01/08/2024 sendo, que para o recebimento das vendas retroativas do mês de agosto, o participante deverá se cadastrar e aceitar o regulamento dentro do período de 16/09/2024 a 30/09/2024.',
  },
  {
    id: 7,
    pergunta: 'Qual a conversão de venda por ponto?',
    resposta:
      'Para entender quanto cada serviço e produto vendido vale em pontos, acesse o regulamento no site da campanha e verifique o anexo I.',
  },
  {
    id: 8,
    pergunta: 'Quando eu recebo os pontos das vendas efetuadas?',
    resposta:
      'Uma base única de pontos é compartilhada no giga clube no 15º (décimo) dia do mês seguinte, e validará as tratativas de contratos efetivadas no mês anterior.',
  },
  {
    id: 9,
    pergunta: 'A partir de quando posso resgatar meus pontos?',
    resposta:
      'Você pode usar seus pontos assim que eles forem validados ou então acumulá-los. Fique atento, pois todos os pontos devem ser resgatados durante os 6 meses de campanha.',
  },
  {
    id: 10,
    pergunta: 'Onde eu resgato meus pontos?',
    resposta:
      'Você pode resgatar seus pontos através do Catálogo de Prêmios disponível no menu ao lado esquerdo.',
  },
  {
    id: 11,
    pergunta: 'Em caso de dúvidas entre em contato:',
    resposta:
      'Você pode entrar em contato com nossa equipe via WhatsApp, pelo número (11) 2776-6205 (SLA primeiro retorno 48hrs úteis). O atendimento será dentro do horário comercial (09:00 hrs às 18:00hrs) de segunda a sexta-feira, exceto em feriados nacionais. E para dúvidas em relação ao seu produto resgatado ou catálogo de prêmios, entre em contato com a Vertem através do chat na página do catálogo, atendimento telefônico pelo número (11)3514-7678 (SLA primeiro retorno 48hrs úteis) O atendimento será dentro do horário comercial (09:00 hrs às 18:00hrs) de segunda a sextafeira, exceto em feriados nacionais. É dever do Participante responder todas as perguntas e dados corretamente, para dar sequência a tratativa.',
  },
  {
    id: 12,
    pergunta: 'Onde posso acessar o regulamento?',
    resposta:
      'Ele está disponível em seu perfil do giga, na opção “saiba mais”.',
  },
];
