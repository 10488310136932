const na = 'N/A';

const getStatus = (status) => {
  if (status === 1 || status === 2) {
    return 'Ativo';
  } else if (status === 3) {
    return 'Inativo';
  }
  return na;
};

const columns = {
  all: [
    {
      name: 'Status',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => getStatus(row.status),
      cell: (row) => getStatus(row.status),
    },
    {
      name: 'Data do Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => (row.data_aceite ? row.data_aceite : na),
      selector: (row) => (row.data_aceite ? row.data_aceite : na),
    },
    {
      name: 'Hora do Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.hora_aceite ? row.hora_aceite : na),
      cell: (row) => (row.hora_aceite ? row.hora_aceite : na),
    },
    {
      name: 'Data do Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_ultimo_login ? row.data_ultimo_login : na),
      cell: (row) => (row.data_ultimo_login ? row.data_ultimo_login : na),
    },
    {
      name: 'Hora do Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : na),
      cell: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : na),
    },
    {
      name: 'Id Usuário',
      sortable: true,
      grow: 1,
      minWidth: '120px',
      selector: (row) => (row.usu_id ? row.usu_id : na),
      cell: (row) => (row.usu_id ? row.usu_id : na),
    },
    {
      name: 'Nome',
      sortable: true,
      grow: 1,
      minWidth: '330px',
      selector: (row) => (row.nome ? row.nome : na),
      cell: (row) => (row.nome ? row.nome : na),
    },
    {
      name: 'CPF',
      sortable: true,
      grow: 1,
      minWidth: '160px',
      selector: (row) => (row.cpf ? row.cpf : na),
      cell: (row) => (row.cpf ? row.cpf : na),
    },
    {
      name: 'Cargo',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.cargo ? row.cargo : na),
      cell: (row) => (row.cargo ? row.cargo : na),
    },
    {
      name: 'Email',
      sortable: true,
      grow: 0,
      minWidth: '300px',
      selector: (row) => (row.email ? row.email : na),
      cell: (row) => (row.email ? row.email : na),
    },
    {
      name: 'Data de Nascimento',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_nascimento ? row.data_nascimento : na),
      cell: (row) => (row.data_nascimento ? row.data_nascimento : na),
    },
    {
      name: 'Celular',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.celular ? row.celular : na),
      cell: (row) => (row.celular ? row.celular : na),
    },
    {
      name: 'Endereço',
      sortable: true,
      grow: 1,
      minWidth: '300px',
      selector: (row) => (row.endereco ? row.endereco : na),
      cell: (row) => (row.endereco ? row.endereco : na),
    },
    {
      name: 'Número',
      sortable: true,
      grow: 1,
      selector: (row) => (row.numero ? row.numero : na),
      cell: (row) => (row.numero ? row.numero : na),
    },
    {
      name: 'Complemento',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.complemento ? row.complemento : na),
      cell: (row) => (row.complemento ? row.complemento : na),
    },
    {
      name: 'CEP',
      sortable: true,
      grow: 1,
      selector: (row) => (row.cep ? row.cep : na),
      cell: (row) => (row.cep ? row.cep : na),
    },
    {
      name: 'Bairro',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.bairro ? row.bairro : na),
      cell: (row) => (row.bairro ? row.bairro : na),
    },
    {
      name: 'Cidade',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.cidade ? row.cidade : na),
      cell: (row) => (row.cidade ? row.cidade : na),
    },
    {
      name: 'Estado',
      sortable: true,
      grow: 1,
      selector: (row) => (row.estado ? row.estado : na),
      cell: (row) => (row.estado ? row.estado : na),
    },
    {
      name: 'Regional',
      sortable: true,
      grow: 1,
      selector: (row) => (row.regional ? row.regional : na),
      cell: (row) => (row.regional ? row.regional : na),
    },
    {
      name: 'PDV',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.pdv ? row.pdv : na),
      cell: (row) => (row.pdv ? row.pdv : na),
    },
    {
      name: 'Rede',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.rede ? row.rede : na),
      cell: (row) => (row.rede ? row.rede : na),
    },
    {
      name: 'Pedidos',
      sortable: true,
      grow: 1,
      selector: (row) => (row.pedidos ? row.pedidos : na),
      cell: (row) => (row.pedidos ? row.pedidos : na),
    },
    {
      name: 'Vendas',
      sortable: true,
      grow: 1,
      selector: (row) => (row.vendas ? row.vendas : na),
      cell: (row) => (row.vendas ? row.vendas : na),
    },
    {
      name: 'Pontos Bônus',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
      cell: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
    },
    {
      name: 'Pontos Aprovados',
      sortable: true,
      minWidth: '180px',
      grow: 1,
      selector: (row) => (row.pontos_aprovados ? row.pontos_aprovados : na),
      cell: (row) => (row.pontos_aprovados ? row.pontos_aprovados : na),
    },
    {
      name: 'Pontos Pendentes',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_pendentes ? row.pontos_pendentes : na),
      cell: (row) => (row.pontos_pendentes ? row.pontos_pendentes : na),
    },
    {
      name: 'Pontos Resgatados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_resgatados ? row.pontos_resgatados : na),
      cell: (row) => (row.pontos_resgatados ? row.pontos_resgatados : na),
    },
    {
      name: 'Pontos Reprovados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_reprovados ? row.pontos_reprovados : na),
      cell: (row) => (row.pontos_reprovados ? row.pontos_reprovados : na),
    },
    {
      name: 'Pontos Estornados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_estornados ? row.pontos_estornados : na),
      cell: (row) => (row.pontos_estornados ? row.pontos_estornados : na),
    },
    {
      name: 'Pontos Aniversário',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_aniversario ? row.pontos_aniversario : na),
      cell: (row) => (row.pontos_aniversario ? row.pontos_aniversario : na),
    },
    {
      name: 'Saldo',
      sortable: true,
      grow: 1,
      selector: (row) => (row.saldo ? row.saldo : na),
      cell: (row) => (row.saldo ? row.saldo : na),
    },
    {
      name: 'Pontos Bloqueados Premmiar',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) =>
        row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : na,
      cell: (row) =>
        row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : na,
    },
    {
      name: 'Status',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => getStatus(row.status),
      cell: (row) => getStatus(row.status),
    },
    {
      name: 'Faixa',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.faixa ? row.faixa : na),
      cell: (row) => (row.faixa ? row.faixa : na),
    },
  ],
  recarga: [
    {
      name: 'Status',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => getStatus(row.status),
      cell: (row) => getStatus(row.status),
    },
    {
      name: 'Data Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.data_aceite ? row.data_aceite : na),
      cell: (row) => (row.data_aceite ? row.data_aceite : na),
    },
    {
      name: 'Hora Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.hora_aceite ? row.hora_aceite : na),
      cell: (row) => (row.hora_aceite ? row.hora_aceite : na),
    },
    {
      name: 'Data Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_ultimo_login ? row.data_ultimo_login : na),
      cell: (row) => (row.data_ultimo_login ? row.data_ultimo_login : na),
    },
    {
      name: 'Hora Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : na),
      cell: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : na),
    },
    {
      name: 'ID Usuário',
      sortable: true,
      grow: 1,
      minWidth: '120px',
      selector: (row) => (row.usu_id ? row.usu_id : na),
      cell: (row) => (row.usu_id ? row.usu_id : na),
    },
    {
      name: 'Nome',
      sortable: true,
      grow: 1,
      minWidth: '330px',
      selector: (row) => (row.nome ? row.nome : na),
      cell: (row) => (row.nome ? row.nome : na),
    },
    {
      name: 'Nome Fantasia',
      sortable: true,
      grow: 1,
      minWidth: '330px',
      selector: (row) => (row.nome_fantasia ? row.nome_fantasia : na),
      cell: (row) => (row.nome_fantasia ? row.nome_fantasia : na),
    },
    {
      name: 'CPF',
      sortable: true,
      grow: 1,
      minWidth: '160px',
      selector: (row) => (row.cpf ? row.cpf : na),
      cell: (row) => (row.cpf ? row.cpf : na),
    },
    {
      name: 'Cargo',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.cargo ? row.cargo : na),
      cell: (row) => (row.cargo ? row.cargo : na),
    },
    {
      name: 'Email',
      sortable: true,
      grow: 0,
      minWidth: '300px',
      selector: (row) => (row.email ? row.email : na),
      cell: (row) => (row.email ? row.email : na),
    },
    {
      name: 'Data Nascimento',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_nascimento ? row.data_nascimento : na),
      cell: (row) => (row.data_nascimento ? row.data_nascimento : na),
    },
    {
      name: 'DDD',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.ddd ? row.ddd : na),
      cell: (row) => (row.ddd ? row.ddd : na),
    },
    {
      name: 'Celular',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.celular ? row.celular : na),
      cell: (row) => (row.celular ? row.celular : na),
    },
    {
      name: 'Endereço',
      sortable: true,
      grow: 1,
      minWidth: '300px',
      selector: (row) => (row.endereco ? row.endereco : na),
      cell: (row) => (row.endereco ? row.endereco : na),
    },
    {
      name: 'Número',
      sortable: true,
      grow: 1,
      selector: (row) => (row.numero ? row.numero : na),
      cell: (row) => (row.numero ? row.numero : na),
    },
    {
      name: 'Complemento',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.complemento ? row.complemento : na),
      cell: (row) => (row.complemento ? row.complemento : na),
    },
    {
      name: 'CEP',
      sortable: true,
      grow: 1,
      selector: (row) => (row.cep ? row.cep : na),
      cell: (row) => (row.cep ? row.cep : na),
    },
    {
      name: 'Bairro',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.bairro ? row.bairro : na),
      cell: (row) => (row.bairro ? row.bairro : na),
    },
    {
      name: 'Cidade',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.cidade ? row.cidade : na),
      cell: (row) => (row.cidade ? row.cidade : na),
    },
    {
      name: 'Estado',
      sortable: true,
      grow: 1,
      selector: (row) => (row.estado ? row.estado : na),
      cell: (row) => (row.estado ? row.estado : na),
    },
    {
      name: 'Regional',
      sortable: true,
      grow: 1,
      selector: (row) => (row.regional ? row.regional : na),
      cell: (row) => (row.regional ? row.regional : na),
    },
    {
      name: 'Região',
      sortable: true,
      grow: 1,
      selector: (row) => (row.regiao ? row.regiao : na),
      cell: (row) => (row.regiao ? row.regiao : na),
    },
    {
      name: 'IBGE',
      sortable: true,
      grow: 1,
      selector: (row) => (row.ibge ? row.ibge : na),
      cell: (row) => (row.ibge ? row.ibge : na),
    },
    {
      name: 'PDC',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.pdv ? row.pdv : na),
      cell: (row) => (row.pdv ? row.pdv : na),
    },
    {
      name: 'Distribuidor',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.rede ? row.rede : na),
      cell: (row) => (row.rede ? row.rede : na),
    },
    {
      name: 'Pedidos',
      sortable: true,
      grow: 1,
      selector: (row) => (row.pedidos ? row.pedidos : na),
      cell: (row) => (row.pedidos ? row.pedidos : na),
    },
    {
      name: 'Vendas',
      sortable: true,
      grow: 1,
      selector: (row) => (row.vendas ? row.vendas : na),
      cell: (row) => (row.vendas ? row.vendas : na),
    },
    {
      name: 'Pontos Bônus',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
      cell: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
    },
    {
      name: 'Pontos Aprovados',
      sortable: true,
      minWidth: '180px',
      grow: 1,
      selector: (row) => (row.pontos_aprovados ? row.pontos_aprovados : na),
      cell: (row) => (row.pontos_aprovados ? row.pontos_aprovados : na),
    },
    {
      name: 'Pontos Pendentes',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_pendentes ? row.pontos_pendentes : na),
      cell: (row) => (row.pontos_pendentes ? row.pontos_pendentes : na),
    },
    {
      name: 'Pontos Resgatados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_resgatados ? row.pontos_resgatados : na),
      cell: (row) => (row.pontos_resgatados ? row.pontos_resgatados : na),
    },
    {
      name: 'Pontos Reprovados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_reprovados ? row.pontos_reprovados : na),
      row: (row) => (row.pontos_reprovados ? row.pontos_reprovados : na),
    },
    {
      name: 'Pontos Estornados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_estornados ? row.pontos_estornados : na),
      cell: (row) => (row.pontos_estornados ? row.pontos_estornados : na),
    },
    {
      name: 'Pontos Aniversário',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_aniversario ? row.pontos_aniversario : na),
      cell: (row) => (row.pontos_aniversario ? row.pontos_aniversario : na),
    },
    {
      name: 'Saldo',
      sortable: true,
      grow: 1,
      selector: (row) => (row.saldo ? row.saldo : na),
      cell: (row) => (row.saldo ? row.saldo : na),
    },
    {
      name: 'Pontos Bloqueados Premmiar',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) =>
        row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : na,
      cell: (row) =>
        row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : na,
    },
    {
      name: 'Perfil Detalhe',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.perfil_detalhe ? row.perfil_detalhe : na),
      cell: (row) => (row.perfil_detalhe ? row.perfil_detalhe : na),
    },
  ],
  digital: [
    {
      name: 'Status',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => getStatus(row.status),
      cell: (row) => getStatus(row.status),
    },
    {
      name: 'Data do Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.data_aceite ? row.data_aceite : na),
      cell: (row) => (row.data_aceite ? row.data_aceite : na),
    },
    {
      name: 'Hora do Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.hora_aceite ? row.hora_aceite : na),
      cell: (row) => (row.hora_aceite ? row.hora_aceite : na),
    },
    {
      name: 'Data do Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_ultimo_login ? row.data_ultimo_login : na),
      cell: (row) => (row.data_ultimo_login ? row.data_ultimo_login : na),
    },
    {
      name: 'Hora do Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : na),
      cell: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : na),
    },
    {
      name: 'Id Usuário',
      sortable: true,
      grow: 1,
      minWidth: '120px',
      selector: (row) => (row.usu_id ? row.usu_id : na),
      cell: (row) => (row.usu_id ? row.usu_id : na),
    },
    {
      name: 'Nome',
      sortable: true,
      grow: 1,
      minWidth: '330px',
      selector: (row) => (row.nome ? row.nome : na),
      cell: (row) => (row.nome ? row.nome : na),
    },
    {
      name: 'CPF',
      sortable: true,
      grow: 1,
      minWidth: '160px',
      selector: (row) => (row.cpf ? row.cpf : na),
      cell: (row) => (row.cpf ? row.cpf : na),
    },
    {
      name: 'Cargo',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.cargo ? row.cargo : na),
      cell: (row) => (row.cargo ? row.cargo : na),
    },
    {
      name: 'Canal Digital',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.canal_digital ? row.canal_digital : na),
      cell: (row) => (row.canal_digital ? row.canal_digital : na),
    },
    {
      name: 'Email',
      sortable: true,
      grow: 0,
      minWidth: '300px',
      selector: (row) => (row.email ? row.email : na),
      cell: (row) => (row.email ? row.email : na),
    },
    {
      name: 'Data de nascimento',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_nascimento ? row.data_nascimento : na),
      cell: (row) => (row.data_nascimento ? row.data_nascimento : na),
    },
    {
      name: 'Celular',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.celular ? row.celular : na),
      cell: (row) => (row.celular ? row.celular : na),
    },
    {
      name: 'Endereço',
      sortable: true,
      grow: 1,
      minWidth: '300px',
      selector: (row) => (row.endereco ? row.endereco : na),
      cell: (row) => (row.endereco ? row.endereco : na),
    },
    {
      name: 'Número',
      sortable: true,
      grow: 1,
      selector: (row) => (row.numero ? row.numero : na),
      cell: (row) => (row.numero ? row.numero : na),
    },
    {
      name: 'Complemento',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.complemento ? row.complemento : na),
      cell: (row) => (row.complemento ? row.complemento : na),
    },
    {
      name: 'CEP',
      sortable: true,
      grow: 1,
      selector: (row) => (row.cep ? row.cep : na),
      cell: (row) => (row.cep ? row.cep : na),
    },
    {
      name: 'Bairro',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.bairro ? row.bairro : na),
      cell: (row) => (row.bairro ? row.bairro : na),
    },
    {
      name: 'Cidade',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.cidade ? row.cidade : na),
      cell: (row) => (row.cidade ? row.cidade : na),
    },
    {
      name: 'Estado',
      sortable: true,
      grow: 1,
      selector: (row) => (row.estado ? row.estado : na),
      cell: (row) => (row.estado ? row.estado : na),
    },
    {
      name: 'Regional',
      sortable: true,
      grow: 1,
      selector: (row) => (row.regional ? row.regional : na),
      cell: (row) => (row.regional ? row.regional : na),
    },
    {
      name: 'PDV',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.pdv ? row.pdv : na),
      cell: (row) => (row.pdv ? row.pdv : na),
    },
    {
      name: 'Canal',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.canal ? row.canal : na),
      cell: (row) => (row.canal ? row.canal : na),
    },
    {
      name: 'Pedidos',
      sortable: true,
      grow: 1,
      selector: (row) => (row.pedidos ? row.pedidos : na),
      cell: (row) => (row.pedidos ? row.pedidos : na),
    },
    {
      name: 'Pontos Bônus',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
      cell: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
    },
    {
      name: 'Pontos Aprovados',
      sortable: true,
      minWidth: '180px',
      grow: 1,
      selector: (row) => (row.pontos_aprovados ? row.pontos_aprovados : na),
      cell: (row) => (row.pontos_aprovados ? row.pontos_aprovados : na),
    },
    {
      name: 'Pontos Pendentes',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_pendentes ? row.pontos_pendentes : na),
      cell: (row) => (row.pontos_pendentes ? row.pontos_pendentes : na),
    },
    {
      name: 'Pontos Resgatados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_resgatados ? row.pontos_resgatados : na),
      cell: (row) => (row.pontos_resgatados ? row.pontos_resgatados : na),
    },
    {
      name: 'Pontos Reprovados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_reprovados ? row.pontos_reprovados : na),
      cell: (row) => (row.pontos_reprovados ? row.pontos_reprovados : na),
    },
    {
      name: 'Pontos Estornados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_estornados ? row.pontos_estornados : na),
      cell: (row) => (row.pontos_estornados ? row.pontos_estornados : na),
    },
    {
      name: 'Pontos Aniversário',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_aniversario ? row.pontos_aniversario : na),
      cell: (row) => (row.pontos_aniversario ? row.pontos_aniversario : na),
    },
    {
      name: 'Saldo',
      sortable: true,
      grow: 1,
      selector: (row) => (row.saldo ? row.saldo : na),
      cell: (row) => (row.saldo ? row.saldo : na),
    },
    {
      name: 'Pontos Bloqueados Premmiar',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) =>
        row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : na,
      cell: (row) =>
        row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : na,
    },
  ],
  pap: [
    {
      name: 'Status',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => getStatus(row.status),
      cell: (row) => getStatus(row.status),
    },
    {
      name: 'Data do Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.data_aceite ? row.data_aceite : na),
    },
    {
      name: 'Hora do Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.hora_aceite ? row.hora_aceite : na),
    },
    {
      name: 'Data do Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_ultimo_login ? row.data_ultimo_login : na),
    },
    {
      name: 'Hora do Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : na),
    },
    {
      name: 'Id Usuário',
      sortable: true,
      grow: 1,
      minWidth: '120px',
      selector: (row) => (row.usu_id ? row.usu_id : na),
    },
    {
      name: 'G20',
      sortable: true,
      grow: 1,
      minWidth: '120px',
      selector: (row) => (row.g20 ? row.g20 : na),
    },
    {
      name: 'Equipe de Venda',
      sortable: true,
      grow: 1,
      minWidth: '120px',
      selector: (row) => (row.equipe_venda ? row.equipe_venda : na),
    },
    {
      name: 'Nome',
      sortable: true,
      grow: 1,
      minWidth: '330px',
      selector: (row) => (row.nome ? row.nome : na),
    },
    {
      name: 'CPF',
      sortable: true,
      grow: 1,
      minWidth: '160px',
      selector: (row) => (row.cpf ? row.cpf : na),
    },
    {
      name: 'Cargo',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.cargo ? row.cargo : na),
    },
    {
      name: 'Regional',
      sortable: true,
      grow: 1,
      selector: (row) => (row.regional ? row.regional : na),
    },
    {
      name: 'Email',
      sortable: true,
      grow: 0,
      minWidth: '300px',
      selector: (row) => (row.email ? row.email : na),
    },
    {
      name: 'Data de Nascimento',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_nascimento ? row.data_nascimento : na),
    },
    {
      name: 'Celular',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.celular ? row.celular : na),
    },
    {
      name: 'Endereço',
      sortable: true,
      grow: 1,
      minWidth: '300px',
      selector: (row) => (row.endereco ? row.endereco : na),
    },
    {
      name: 'Número',
      sortable: true,
      grow: 1,
      selector: (row) => (row.numero ? row.numero : na),
    },
    {
      name: 'Complemento',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.complemento ? row.complemento : na),
    },
    {
      name: 'CEP',
      sortable: true,
      grow: 1,
      selector: (row) => (row.cep ? row.cep : na),
    },
    {
      name: 'Bairro',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.bairro ? row.bairro : na),
    },
    {
      name: 'Cidade',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.cidade ? row.cidade : na),
    },
    {
      name: 'Estado',
      sortable: true,
      grow: 1,
      selector: (row) => (row.estado ? row.estado : na),
    },
    {
      name: 'Pedidos',
      sortable: true,
      grow: 1,
      selector: (row) => (row.pedidos ? row.pedidos : na),
    },
    {
      name: 'Vendas',
      sortable: true,
      grow: 1,
      selector: (row) => (row.vendas ? row.vendas : na),
    },
    {
      name: 'Pontos Aprovados',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_aprovados ? row.pontos_aprovados : na),
    },
    {
      name: 'Pontos Pendentes',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_pendentes ? row.pontos_pendentes : na),
    },
    {
      name: 'Pontos Reprovados',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_reprovados ? row.pontos_reprovados : na),
    },
    {
      name: 'Pontos Resgatados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_resgatados ? row.pontos_resgatados : na),
    },
    {
      name: 'Pontos Estornados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_estornados ? row.pontos_estornados : na),
    },
    {
      name: 'Pontos Bônus',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
    },
    {
      name: 'Pontos Aniversário',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_aniversario ? row.pontos_aniversario : na),
    },
    {
      name: 'Pontos Treinamento',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_treinamento ? row.pontos_treinamento : na),
    },
    {
      name: 'Pontos Não Atingiu Meta',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) =>
        row.pontos_nao_atingiu_meta ? row.pontos_nao_atingiu_meta : na,
    },
    {
      name: 'Saldo',
      sortable: true,
      grow: 1,
      selector: (row) => (row.saldo ? row.saldo : na),
    },
    {
      name: 'Pontos Bloqueados Premmiar',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) =>
        row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : na,
    },
  ],
  pap_pc: [
    {
      name: 'Status',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => getStatus(row.status),
      cell: (row) => getStatus(row.status),
    },
    {
      name: 'Data do Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.data_aceite ? row.data_aceite : na),
    },
    {
      name: 'Hora do Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.hora_aceite ? row.hora_aceite : na),
    },
    {
      name: 'Data do Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_ultimo_login ? row.data_ultimo_login : na),
    },
    {
      name: 'Hora do Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : na),
    },
    {
      name: 'Id Usuário',
      sortable: true,
      grow: 1,
      minWidth: '120px',
      selector: (row) => (row.usu_id ? row.usu_id : na),
    },
    {
      name: 'G20',
      sortable: true,
      grow: 1,
      minWidth: '120px',
      selector: (row) => (row.g20 ? row.g20 : na),
    },
    {
      name: 'Equipe de Venda',
      sortable: true,
      grow: 1,
      minWidth: '220px',
      selector: (row) => (row.equipe_venda ? row.equipe_venda : na),
    },
    {
      name: 'Segmento',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.segmento ? row.segmento : na),
    },
    {
      name: 'Nome',
      sortable: true,
      grow: 1,
      minWidth: '330px',
      selector: (row) => (row.nome ? row.nome : na),
    },
    {
      name: 'CPF',
      sortable: true,
      grow: 1,
      minWidth: '160px',
      selector: (row) => (row.cpf ? row.cpf : na),
    },
    {
      name: 'ID Usuario Claro',
      sortable: true,
      grow: 1,
      minWidth: '160px',
      selector: (row) => (row.id_usuario_claro ? row.id_usuario_claro : na),
    },
    {
      name: 'Cargo',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.cargo ? row.cargo : na),
    },
    {
      name: 'Regional',
      sortable: true,
      grow: 1,
      selector: (row) => (row.regional ? row.regional : na),
    },
    {
      name: 'Email',
      sortable: true,
      grow: 0,
      minWidth: '300px',
      selector: (row) => (row.email ? row.email : na),
    },
    {
      name: 'Data de Nascimento',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_nascimento ? row.data_nascimento : na),
    },
    {
      name: 'Celular',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.celular ? row.celular : na),
    },
    {
      name: 'Endereço',
      sortable: true,
      grow: 1,
      minWidth: '300px',
      selector: (row) => (row.endereco ? row.endereco : na),
    },
    {
      name: 'Número',
      sortable: true,
      grow: 1,
      selector: (row) => (row.numero ? row.numero : na),
    },
    {
      name: 'Complemento',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.complemento ? row.complemento : na),
    },
    {
      name: 'CEP',
      sortable: true,
      grow: 1,
      selector: (row) => (row.cep ? row.cep : na),
    },
    {
      name: 'Bairro',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.bairro ? row.bairro : na),
    },
    {
      name: 'Cidade',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.cidade ? row.cidade : na),
    },
    {
      name: 'Estado',
      sortable: true,
      grow: 1,
      selector: (row) => (row.estado ? row.estado : na),
    },
    {
      name: 'Pedidos',
      sortable: true,
      grow: 1,
      selector: (row) => (row.pedidos ? row.pedidos : na),
    },
    {
      name: 'Pontos Aprovados',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_aprovados ? row.pontos_aprovados : na),
    },
    {
      name: 'Pontos Pendentes',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_pendentes ? row.pontos_pendentes : na),
    },
    {
      name: 'Pontos Reprovados',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_reprovados ? row.pontos_reprovados : na),
    },
    {
      name: 'Pontos Resgatados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_resgatados ? row.pontos_resgatados : na),
    },
    {
      name: 'Pontos Estornados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_estornados ? row.pontos_estornados : na),
    },
    {
      name: 'Pontos Bônus',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
    },
    {
      name: 'Pontos Aniversário',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_aniversario ? row.pontos_aniversario : na),
    },
    {
      name: 'Pontos Treinamento',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_treinamento ? row.pontos_treinamento : na),
    },
    {
      name: 'Pontos Não Atingiu Meta',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) =>
        row.pontos_nao_atingiu_meta ? row.pontos_nao_atingiu_meta : na,
    },
    {
      name: 'Saldo',
      sortable: true,
      grow: 1,
      selector: (row) => (row.saldo ? row.saldo : na),
    },
    {
      name: 'Pontos Bloqueados Premmiar',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) =>
        row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : na,
    },
  ],
  aparelhos: [
    {
      name: 'Status',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => getStatus(row.status),
      cell: (row) => getStatus(row.status),
    },
    {
      name: 'Data do Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => (row.data_aceite ? row.data_aceite : na),
      selector: (row) => (row.data_aceite ? row.data_aceite : na),
    },
    {
      name: 'Hora do Aceite',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.hora_aceite ? row.hora_aceite : na),
      cell: (row) => (row.hora_aceite ? row.hora_aceite : na),
    },
    {
      name: 'Data do Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_ultimo_login ? row.data_ultimo_login : na),
      cell: (row) => (row.data_ultimo_login ? row.data_ultimo_login : na),
    },
    {
      name: 'Hora do Último Login',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : na),
      cell: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : na),
    },
    {
      name: 'Id Usuário',
      sortable: true,
      grow: 1,
      minWidth: '120px',
      selector: (row) => (row.usu_id ? row.usu_id : na),
      cell: (row) => (row.usu_id ? row.usu_id : na),
    },
    {
      name: 'Nome',
      sortable: true,
      grow: 1,
      minWidth: '330px',
      selector: (row) => (row.nome ? row.nome : na),
      cell: (row) => (row.nome ? row.nome : na),
    },
    {
      name: 'CPF',
      sortable: true,
      grow: 1,
      minWidth: '160px',
      selector: (row) => (row.cpf ? row.cpf : na),
      cell: (row) => (row.cpf ? row.cpf : na),
    },
    {
      name: 'Cargo',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.cargo ? row.cargo : na),
      cell: (row) => (row.cargo ? row.cargo : na),
    },
    {
      name: 'Email',
      sortable: true,
      grow: 0,
      minWidth: '300px',
      selector: (row) => (row.email ? row.email : na),
      cell: (row) => (row.email ? row.email : na),
    },
    {
      name: 'Data de Nascimento',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.data_nascimento ? row.data_nascimento : na),
      cell: (row) => (row.data_nascimento ? row.data_nascimento : na),
    },
    {
      name: 'Celular',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.celular ? row.celular : na),
      cell: (row) => (row.celular ? row.celular : na),
    },
    {
      name: 'Endereço',
      sortable: true,
      grow: 1,
      minWidth: '300px',
      selector: (row) => (row.endereco ? row.endereco : na),
      cell: (row) => (row.endereco ? row.endereco : na),
    },
    {
      name: 'Número',
      sortable: true,
      grow: 1,
      selector: (row) => (row.numero ? row.numero : na),
      cell: (row) => (row.numero ? row.numero : na),
    },
    {
      name: 'Complemento',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.complemento ? row.complemento : na),
      cell: (row) => (row.complemento ? row.complemento : na),
    },
    {
      name: 'CEP',
      sortable: true,
      grow: 1,
      selector: (row) => (row.cep ? row.cep : na),
      cell: (row) => (row.cep ? row.cep : na),
    },
    {
      name: 'Bairro',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.bairro ? row.bairro : na),
      cell: (row) => (row.bairro ? row.bairro : na),
    },
    {
      name: 'Cidade',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.cidade ? row.cidade : na),
      cell: (row) => (row.cidade ? row.cidade : na),
    },
    {
      name: 'Estado',
      sortable: true,
      grow: 1,
      selector: (row) => (row.estado ? row.estado : na),
      cell: (row) => (row.estado ? row.estado : na),
    },
    {
      name: 'Regional',
      sortable: true,
      grow: 1,
      selector: (row) => (row.regional ? row.regional : na),
      cell: (row) => (row.regional ? row.regional : na),
    },
    {
      name: 'PDV',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.pdv ? row.pdv : na),
      cell: (row) => (row.pdv ? row.pdv : na),
    },
    {
      name: 'Rede',
      sortable: true,
      grow: 1,
      minWidth: '150px',
      selector: (row) => (row.rede ? row.rede : na),
      cell: (row) => (row.rede ? row.rede : na),
    },
    {
      name: 'Pedidos',
      sortable: true,
      grow: 1,
      selector: (row) => (row.pedidos ? row.pedidos : na),
      cell: (row) => (row.pedidos ? row.pedidos : na),
    },
    {
      name: 'Vendas',
      sortable: true,
      grow: 1,
      selector: (row) => (row.vendas ? row.vendas : na),
      cell: (row) => (row.vendas ? row.vendas : na),
    },
    {
      name: 'Pontos Bônus',
      sortable: true,
      minWidth: '150px',
      grow: 1,
      selector: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
      cell: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
    },
    {
      name: 'Pontos Aprovados',
      sortable: true,
      minWidth: '180px',
      grow: 1,
      selector: (row) => (row.pontos_aprovados ? row.pontos_aprovados : na),
      cell: (row) => (row.pontos_aprovados ? row.pontos_aprovados : na),
    },
    {
      name: 'Pontos Pendentes',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_pendentes ? row.pontos_pendentes : na),
      cell: (row) => (row.pontos_pendentes ? row.pontos_pendentes : na),
    },
    {
      name: 'Pontos Resgatados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_resgatados ? row.pontos_resgatados : na),
      cell: (row) => (row.pontos_resgatados ? row.pontos_resgatados : na),
    },
    {
      name: 'Pontos Reprovados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_reprovados ? row.pontos_reprovados : na),
      cell: (row) => (row.pontos_reprovados ? row.pontos_reprovados : na),
    },
    {
      name: 'Pontos Estornados',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_estornados ? row.pontos_estornados : na),
      cell: (row) => (row.pontos_estornados ? row.pontos_estornados : na),
    },
    {
      name: 'Pontos Aniversário',
      sortable: true,
      grow: 1,
      minWidth: '180px',
      selector: (row) => (row.pontos_aniversario ? row.pontos_aniversario : na),
      cell: (row) => (row.pontos_aniversario ? row.pontos_aniversario : na),
    },
    {
      name: 'Saldo',
      sortable: true,
      grow: 1,
      selector: (row) => (row.saldo ? row.saldo : na),
      cell: (row) => (row.saldo ? row.saldo : na),
    },
    {
      name: 'Pontos Bloqueados Premmiar',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) =>
        row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : na,
      cell: (row) =>
        row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : na,
    },
    {
      name: 'Data última atualização',
      sortable: true,
      grow: 1,
      minWidth: '200px',
      selector: (row) => (row.data_atualizacao ? row.data_atualizacao : na),
      cell: (row) => (row.data_atualizacao ? row.data_atualizacao : na),
    },
  ],
};

export default columns;
